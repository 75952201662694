import { batch, useDispatch } from 'react-redux';

import { useMediaPlayerContext } from '@/components/app/contentPlayer/MediaPlayerProvider';
import { useApi } from '@/hooks/api';
import { useRouterPush } from '@/hooks/utils/useRouterPush';
import { setGuide } from '@/store/guide/actions';
import { setProgram } from '@/store/program/actions';
import { Program } from '@/store/program/types';
import useIsInMsTeamsMeeting from '@/teams-calm/src/live-share-hooks/useIsInMsTeamsMeeting';
import useShareToStage from '@/teams-calm/src/live-share-hooks/useShareToStage';
import getUrl from '@/utils/getUrl';

interface OnGuidePlay {
	(guideId: string, program?: Program | null): Promise<void>;
}

export const useGuidePlay = (): OnGuidePlay => {
	const apiRequest = useApi();
	const dispatch = useDispatch();
	const routerPush = useRouterPush();
	const { onAudioStart, onVideoStart, onStop, shouldPreventAppNav } = useMediaPlayerContext();
	const { checkIfInMeeting } = useIsInMsTeamsMeeting();
	const shareToStage = useShareToStage();

	const onGuidePlay: OnGuidePlay = async (guideId, program) => {
		try {
			if (shouldPreventAppNav) {
				const isInMsTeamsMeeting = await checkIfInMeeting();
				const pathSegment = isInMsTeamsMeeting ? 'meeting-player' : 'player';
				const url = `${getUrl('www')}/teams-calm/${pathSegment}/${guideId}`;
				if (isInMsTeamsMeeting) {
					await shareToStage.trigger(url);
					return;
				}
				await routerPush(url);
				return;
			}

			const { data } = await apiRequest<{ program: Program }>({
				endpoint: `content/programs/guides/${guideId}`,
				method: 'GET',
				customHeaders: {
					'x-device-capabilities': 'v1;signedCookie;streamOnly',
				},
			});

			const isVideo = data?.program?.guides[0]?.asset?.type === 'video';
			const isDifferentProgram = program?.id !== data?.program?.id;

			const nextGuide = data?.program?.guides[0];
			const nextProgram = data?.program;

			if (nextProgram.meditation_type === 'timer') {
				await routerPush(`/app/player/${nextGuide.id}`);
			}

			batch(() => {
				dispatch(setGuide(nextGuide));
				if (isDifferentProgram) dispatch(setProgram(nextProgram));
			});

			if (!isVideo) {
				onAudioStart(null, nextGuide, nextProgram);
			} else {
				onVideoStart(nextGuide, nextProgram, 'playing', false);
			}
		} catch (error) {
			await onStop();
		}
	};

	return onGuidePlay;
};
