import { dialog } from '@microsoft/teams-js';
import { useRouter } from 'next/router';

import { SearchQueryProps } from '@/components/app/cards/ContentCard/types';
import {
	ApiAsset,
	isCollection,
	isProgramOrGuide,
	isUrl,
	Pack,
	PackItem,
	PackCardItem,
} from '@/components/app/cards/PacksList/types';
import { FORTY_PERCENT_OFF_COUPON } from '@/components/app/layout/DiscountModal';
import { usePacksContext } from '@/components/app/layout/PacksProvider';
import { useSimplifiedSignupModalContext } from '@/context/SimplifiedSignupModalContextProvider';
import { useAnalytics } from '@/hooks/analytics';
import { useGuidePlay } from '@/hooks/app/useGuidePlay';
import { useIsMobile } from '@/hooks/ui/useIsMobile';
import { useAmplitudeExperiment } from '@/hooks/utils/experiments/useAmplitudeExperiment';
import { useFeatureFlags } from '@/hooks/utils/useFeatureFlags';
import { useGetIsTeamsApp } from '@/utils/app/msftTeams';
import getUrl from '@/utils/getUrl';

import useQueryParams from '../utils/useQueryParams';

interface PackDisplayAttributes {
	title?: string;
	handlePackItemClick: (id?: string) => Promise<void>;
	handleUnlockedPackItemClick?: () => Promise<void>;
	key: string;
	duration?: string;
	is_new?: boolean;
	is_unlocked?: boolean;
	image: ApiAsset;
	content_type?: string;
	description?: string;
	promotional_text?: string;
	narrator_name?: string;
	subtitle?: string;
	program_id?: string;
	guide_id?: string;
}

export function determineKey(packItem: PackCardItem, hasSupportPackOfPacksOnWeb?: string): string {
	if (isCollection(packItem) && packItem.onclick_override) {
		return packItem.feed_id;
	}
	if (isUrl(packItem) || (hasSupportPackOfPacksOnWeb && packItem.type === 'pack')) {
		return packItem.title;
	}
	if (isProgramOrGuide(packItem)) {
		const { program_id, guide_id } = packItem;
		return guide_id ?? program_id;
	}

	return 'invalid-pack-item';
}

export function usePackItemAttributes({
	pack,
	packItem,
	searchQuery,
	onClickOverride,
	packFeedName,
}: {
	pack?: Pack;
	packItem: PackCardItem;
	searchQuery?: SearchQueryProps;
	onClickOverride?: (id?: string) => void;
	packFeedName?: string;
}): PackDisplayAttributes {
	const router = useRouter();
	const { logEvent } = useAnalytics();
	const onGuidePlay = useGuidePlay();
	const { setCurrentPack, setCurrentPackItem } = usePacksContext();
	const { flagValues, flagLoading } = useFeatureFlags('support-pack-of-packs-on-web');
	const hasSupportPackOfPacksOnWeb = !flagLoading && flagValues['support-pack-of-packs-on-web'];
	const key = determineKey(packItem, hasSupportPackOfPacksOnWeb as string);
	const { isTeamsApp } = useGetIsTeamsApp();
	const [isMobile] = useIsMobile();
	const query = useQueryParams();
	const { setIsModalOpen } = useSimplifiedSignupModalContext();
	const { isEnrolled } = useAmplitudeExperiment('www-locked-content-upsell', query?.utm_medium === 'paid');

	if (hasSupportPackOfPacksOnWeb && packItem.type === 'pack') {
		const { pack_class, title, subtitle, promotional_text, image, is_unlocked, is_new } =
			packItem as PackItem;
		return {
			key,
			image,
			handlePackItemClick: async () => {
				logEvent({
					eventName: 'Pack : Item : Clicked',
					eventProps: {
						title,
						id: packItem.title, // no actual id needed here
						pack_class,
						source: pack ? pack.pack_class : '',
					},
				});
				await router.push(`/app/class/${pack_class}`);
			},
			title,
			subtitle,
			promotional_text,
			is_unlocked,
			is_new,
		};
	}

	// No duration on collection or url items
	if (isCollection(packItem) && packItem.onclick_override) {
		// TODO: In the future we will remove the necessary onClickOverride
		// assuming we support Packs
		// Right now it's a hacky solution to add it to prefab data on the client
		return {
			...packItem,
			title: packItem.title,
			subtitle: packItem.promotional_text,
			handlePackItemClick: packItem.onclick_override,
			key,
			is_new: packItem.is_new,
			is_unlocked: packItem.is_unlocked,
			image: packItem.image,
		};
	}
	if (isUrl(packItem)) {
		return {
			...packItem,
			title: packItem.title,
			subtitle: packItem.narrator_name,
			handlePackItemClick: async () => {
				logEvent({
					eventName: 'Pack : Item : Clicked',
					eventProps: {
						title: packItem.title,
						id: packItem.title, // no actual id needed here
					},
				});
				await router.push(packItem.url);
			},
			key,
			is_new: packItem.is_new,
			is_unlocked: packItem.is_unlocked,
			image: packItem.image,
		};
	}
	if (isProgramOrGuide(packItem)) {
		// Standard packItem item now (display_type = 'carousel')
		// NOTE: 'carousel' is not the web carousel. That's `banner-carousel`. Web differs from mobile here
		const { duration, is_unlocked, program_id, guide_id } = packItem;
		const durationText =
			duration && duration !== -1 && is_unlocked ? `${Math.floor((duration ?? 0) / 60)} min` : undefined;
		// TODO: ^ This is not translated, we should convert to a different format in the future

		const handleUnlockedPackItemClick = async () => {
			if (program_id === 'programString') {
				// not sure what this is for, but keeping the functionality for now
				await router.push('/app');
				return;
			}

			setCurrentPack(pack ?? null);
			setIsModalOpen(false);

			if (guide_id) {
				await onGuidePlay(guide_id);
			} else {
				await router.push(`/app/program/${program_id}`);
			}
		};

		const onClick = async () => {
			if (onClickOverride) {
				onClickOverride(program_id ?? guide_id);
				return;
			}

			const contentId = guide_id || program_id || null;
			const contentType = guide_id ? 'guide' : program_id ? 'program' : null;

			if (!is_unlocked) {
				logEvent({
					eventName: 'Web : Locked Content : Clicked',
					eventProps: {
						title: packItem.title,
						id: program_id || guide_id || null,
						guide_id: guide_id ?? null,
						query: searchQuery?.query ?? null,
						filter_category: searchQuery?.filter_category ?? null,
						filter_duration: searchQuery?.filter_duration ?? null,
						location: searchQuery?.location ?? null,
						pack_item_index: searchQuery?.pack_item_index ?? null,
						query_id: searchQuery?.query_id ?? null,
						search_index: searchQuery?.search_index ?? null,
						search_object_id: contentType ? `${contentType}_${contentId}` : null,
					},
				});

				// Behavior for MSFT Teams desktop and MSFT Teams mobile will be different than standard upsell
				if (isTeamsApp && !isMobile) {
					dialog.url.open({
						url: `${getUrl('www')}/teams-calm/not-subscribed`,
						size: { height: 250, width: 600 },
					});
				} else if (isTeamsApp) {
					window.open(`/new-member-offer?coupon=${FORTY_PERCENT_OFF_COUPON}`);
				} else {
					if (isEnrolled) {
						setCurrentPackItem(packItem);
					} else {
						await router.push(`/new-member-offer?coupon=${FORTY_PERCENT_OFF_COUPON}`);
					}
				}
				return;
			}

			logEvent({
				eventName: 'Pack : Item : Clicked',
				eventProps: {
					source: packFeedName || null,
					title: packItem.title,
					id: program_id || guide_id || null,
					guide_id: guide_id ?? null,
					query: searchQuery?.query ?? null,
					filter_category: searchQuery?.filter_category ?? null,
					filter_duration: searchQuery?.filter_duration ?? null,
					location: searchQuery?.location ?? null,
					pack_item_index: searchQuery?.pack_item_index ?? null,
					query_id: searchQuery?.query_id ?? null,
					search_index: searchQuery?.search_index ?? null,
					search_object_id: contentType ? `${contentType}_${contentId}` : null,
				},
			});

			await handleUnlockedPackItemClick();
		};

		return {
			...packItem,
			title: packItem.title,
			subtitle: packItem.subtitle,
			handlePackItemClick: onClick,
			handleUnlockedPackItemClick,
			key,
			duration: durationText,
			is_new: packItem.is_new,
			is_unlocked: packItem.is_unlocked,
			image: packItem.image,
			program_id,
			guide_id,
		};
	}

	// NOTE: default return may yield unexpected results
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const defaultPackItem = packItem as any;
	const onClick = async () => {};
	return {
		title: defaultPackItem?.title,
		subtitle: '',
		handlePackItemClick: onClick,
		key: 'invalid-pack-item',
		duration: '',
		is_new: defaultPackItem?.is_new,
		is_unlocked: defaultPackItem?.is_unlocked,
		image: defaultPackItem.image,
	};
}
