import styled, { css } from 'styled-components';
import { palette, theme, ifProp } from 'styled-tools';

import { Text, minWidth } from '@calm-web/design-system';

import lockIcon from 'icons/lock-outline.svg';

export const cardRatioCSS = css`
	aspect-ratio: 0.926 / 1;

	@supports not (aspect-ratio: auto) {
		&:before {
			float: left;
			padding-top: 100%;
			content: '';
		}

		&:after {
			display: block;
			content: '';
			clear: both;
		}
	}
`;

const bannerCardRatioCSS = css`
	aspect-ratio: 1.34 / 1;
`;

export const BackgroundImage = styled.div`
	height: 100%;
	transition: transform ${theme('animation.transition')};

	> div {
		height: 100%;
	}
`;

/**
 * The `el` attribute is set in the `ContentCard` based on the presence
 * of a `pack.title` to use the correct heading hierarchy.
 */
export const Title = styled(Text).attrs({
	styleAs: 'h5',
})`
	font-size: 14px;
	line-height: 20px;
	font-weight: 500;
	margin-top: 0;
	margin-bottom: 2px;
	color: ${palette('white')};

	${minWidth('mobileLarge')} {
		font-size: 16px;
		line-height: 24px;
	}
`;

export const Subtitle = styled(Text).attrs({
	el: 'p',
	styleAs: 'h6',
})<{ isDescription?: boolean }>`
	font-size: 12px;
	font-weight: 500;
	color: ${palette('white')};

	${ifProp(
		'isDescription',
		css`
			height: 4rem;
			overflow-x: hidden;
		`,
	)}

	${minWidth('mobileLarge')} {
		font-size: 13px;
	}
`;

export const Duration = styled.div`
	background: rgba(0, 0, 0, 0.6);
	border-radius: 12px;
	color: ${palette('white')};
	font-size: 11px;
	font-weight: 600;
	left: 8px;
	padding: 4px 6px;
	position: absolute;
	top: 8px;
`;

export const LockIcon = styled(lockIcon)`
	background: rgba(0, 0, 0, 0.6);
	border-radius: 50%;
	height: 32px;
	width: 32px;
	left: 8px;
	padding: 4px 6px;
	position: absolute;
	top: 8px;

	& path {
		fill: ${palette('white')};
	}
`;

export const NewTag = styled.div`
	background: ${palette('white')};
	border-radius: 12px;
	color: rgba(0, 0, 0, 0.8);
	font-size: 11px;
	font-weight: 600;
	right: 8px;
	padding: 4px 6px;
	position: absolute;
	top: 8px;
`;

export const ImageWrapper = styled.div<{ $cardSize: string }>`
	border-radius: 16px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	z-index: 0;
	${cardRatioCSS}
	${p => p.$cardSize === 'banner' && bannerCardRatioCSS};
`;

export const InfoContainer = styled.div`
	position: relative;
	margin-top: 12px;
	margin-bottom: 16px;

	${minWidth('tablet')} {
		margin-bottom: 24px;
	}
`;

export const CardContainer = styled.a<{ isHidden: boolean }>`
	display: block;
	opacity: ${p => (p.isHidden ? 0.2 : 1)};
	pointer-events: ${p => (p.isHidden ? 'none' : 'auto')};
	margin-bottom: 1rem;
	transition: opacity 0.3s ease;

	&:hover {
		${BackgroundImage} {
			transform: scale(1.05);
		}
	}
`;
