import { Tag, Tags } from '../TagsList/types';

export interface PacksListProps {
	packFeedName?: string;
	packClassName?: string;
	activeTag?: Tag;
	packs: Packs;
	tags?: Tags;
	isLoading: boolean;
	showGreeting?: boolean;
	showTitle?: boolean;
}

export interface ApiAsset {
	content_type: string;
	dominant_colors?: string[];
	download_url?: string;
	height?: number;
	id: string;
	size: number;
	type?: 'audio' | 'video' | 'captions' | 'image';
	url: string;
	width?: number;
	duration?: number;
	orientation?: 'landscape' | 'portrait';
	updated_at?: string;
}

export interface ProgramOrGuideItem {
	guide_id?: string;
	program_id: string;
	is_new: boolean;
	is_unlocked: boolean;
	duration: number;
	type: string;
	image: ApiAsset;
	title: string;
	subtitle: string;
	narrator_name: string;
	description: string;
	content_type: string;
	promotional_text: string;
	search_category?: string;
	asset_type?: 'audio' | 'video' | string;
}

export interface CollectionItem {
	feed_id: string;
	is_new: boolean;
	is_unlocked: boolean;
	search_category?: string;
	type: string;
	image: ApiAsset;
	title: string;
	promotional_text: string;
	onclick_override?: () => Promise<void>; // Left ambiguous, but this is a custom type to allow b2b to link to external content
	show_your_plan?: boolean; // Another b2b override - shows "your plan" at the top left of the tile
}

export interface UrlItem {
	type: string;
	url: string;
	is_new: boolean;
	is_unlocked: boolean;
	image: ApiAsset;
	title: string;
	narrator_name: string;
	description: string;
	show_your_plan?: boolean; // Another b2b override - shows "your plan" at the top left of the tile
}

export interface TextItem {
	text: string;
	is_new: boolean;
	is_unlocked: boolean;
	type: 'text';
}

export interface PackItem {
	type: string;
	title: string;
	subtitle?: string;
	promotional_text?: string;
	image: ApiAsset;
	pack_class: string;
	is_unlocked?: boolean;
	is_new?: boolean;
}

export type SinglePackItem = CollectionItem | UrlItem | ProgramOrGuideItem | TextItem | PackItem;

export type PackCardItem = CollectionItem | UrlItem | ProgramOrGuideItem | PackItem;

export interface Pack {
	id: string;
	items: Array<SinglePackItem | CollectionItem | UrlItem>;
	pack_class: string;
	content_description: string;
	display_type: string;
	evaluation_type: string;
	has_more?: boolean;
	title?: string;
	promotional_text?: string;
	content_type?: string;
	description?: string;
	narrator_name?: string;
	subtitle?: string;
}

export function isProgramOrGuide(packItem: SinglePackItem): packItem is ProgramOrGuideItem {
	return (packItem as ProgramOrGuideItem).program_id !== undefined;
}

export function isCollection(packItem: SinglePackItem): packItem is CollectionItem {
	return (packItem as CollectionItem).feed_id !== undefined;
}

export function isUrl(items: SinglePackItem): items is UrlItem {
	return (items as UrlItem)?.url !== undefined;
}

export function isCollectionArray(
	items: Array<CollectionItem | SinglePackItem | UrlItem>,
): items is CollectionItem[] {
	return (items as CollectionItem[])?.[0]?.feed_id !== undefined;
}

export type Packs = Pack[];
