import { defineMessages } from 'react-intl';

export default defineMessages({
	newTag: {
		id: 'app.contentCard.newTag',
		defaultMessage: 'New',
		description: 'Decorator for new content',
	},
	label: {
		id: 'app.contentCard.label',
		defaultMessage: 'Content tile for {title}',
		description: 'Screen reader label for content tile',
	},
});
