import { meeting as microsoftTeamsMeeting } from '@microsoft/teams-js';
import { useCallback, useMemo } from 'react';

import { calmLogger } from '@/utils/calmLogger';

import useIsInMsTeamsMeeting from './useIsInMsTeamsMeeting';

export default function useShareToStage() {
	const { checkIfInMeeting } = useIsInMsTeamsMeeting();

	const action = useCallback(
		async (url: string) => {
			try {
				const isInMeeting = await checkIfInMeeting();
				if (!isInMeeting) {
					return Promise.resolve(false);
				}
				return new Promise((resolve, reject) => {
					microsoftTeamsMeeting.shareAppContentToStage((err, result) => {
						if (result) {
							return resolve(result);
						}
						if (err) {
							// TODO: what sorts of shareAppContentToStage errors are possible? How can we improve this messaging design?
							calmLogger.warn('Error in teams-calm useShareToStage shareAppContentToStage', {
								errorCode: err.errorCode,
								msg: err.message ?? '',
							});
							return resolve(false);
						}
						return resolve(false);
					}, url);
				});
			} catch (err) {
				calmLogger.error('Error in teams-calm useShareToStage', {}, err);
				return Promise.resolve(false);
			}
		},
		[checkIfInMeeting],
	);

	return useMemo(() => ({ trigger: action }), [action]);
}
